@import "bootstrap/scss/bootstrap";

.guides {
  a {
    text-decoration: underline;
  }

  h1 {
    font-size: 1.8rem;
    text-transform: uppercase;
  }

  h2 {
    font-size: 1.5rem;
  }

  h5 {
    color: #7d7f7f;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: .5px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  ol li {
    @extend .mt-3;
  }

  .guide-item {
    @extend .mt-5;
    @extend .border-bottom;
    @extend .pb-4;

    &:first-child {
      @extend .mt-0;
    }
  }

  .aside {
    @extend .col-12;
    @extend .col-md-3;
    @extend .bg-light;
    @extend .border;
    @extend .rounded;
    @extend .h-100;
    @extend .p-3;

    a {
      text-decoration: none;
    }
  }
}
