$gray-800: #343a40;
$primary: $gray-800;

@import "bootstrap";

@import "onboarding";
@import "pages/guides";

.fa-custom {
  color: lighten($gray-800, 10%);
  font-size: 1.3em;
}

.field_with_errors {
  @extend .is-invalid;

  .form-control {
    color: $red;
  }
}

@include media-breakpoint-up(md) {
  nav.sidebar {
    min-height: 90vh;
  }
}
